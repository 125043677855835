
import { Flex, Box, Heading, Text, Button, Image } from '@chakra-ui/react'
import banner1 from '../multimedia/banners/b1.jpg'
import banner2 from '../multimedia/banners/b2.png'
import banner3 from '../multimedia/banners/b3.jpg'
import banner4 from '../multimedia/banners/b4.jpg'
import banner5 from '../multimedia/banners/b5.png'
import banner6 from '../multimedia/banners/b6.jpg'
import { Link } from 'react-router-dom'
import CarouselMulti from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default function HeroBanner3() {

    const responsive = {
        largeDesktop: {
            breakpoint: { max: 3000, min: 1400 },
            items: 1,
            partialVisibilityGutter: 0
        },
        desktop: {
            breakpoint: { max: 1400, min: 1024 },
            items: 1,
            partialVisibilityGutter: 0
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 0
        }
    };

    return (

        <CarouselMulti
            showDots={false}
            rewind={false}
            rewindWithAnimation={true}
            responsive={responsive}
            autoPlay={false}
            autoPlaySpeed={5000}

        >

            <Flex flexDir={{ base: 'column', lg: 'row' }} >
                <Box order={{ base: 2, lg: 1 }} className={'sideLeft'} bg={"white"} w={"full"} maxW={{ base: "full", lg: "40%" }} p={"6px"} pl={"5%"} m={"auto"}>
                    <Heading className='colorEasycar'>¡Despreocúpate!</Heading>
                    <Text as={"b"} fontSize={{ base: "initial", lg: "2xl" }}>
                        Nuestros vehículos cuentan con <span className={"colorEasycar"}>planes de mantenimiento</span> programado para un funcionamiento óptimo.
                    </Text>
                    <Text fontSize={{ base: "initial", lg: "xl" }}>
                        *Se incluye garantía de motor y transmisión
                    </Text>
                    <br /><br />
                    <Link to={"/cars"}>
                        <Button colorScheme={"easycar1"} >Precalifica ahora</Button>
                    </Link>
                    <br /><br />
                    <Text fontSize={{ base: "initial", lg: "xl" }}>
                        *Condiciones y restricciones aplican
                    </Text>
                </Box>
                <Box order={{ base: 1, lg: 2 }} className={'sideRight'} w={"full"} maxW={{ base: "full", lg: "60%" }} >
                    <Image src={banner1} alt={'Carousel Image'} objectFit={'cover'} h={"700px"} w={"full"} />
                </Box>
            </Flex>

            <Flex w={"full"} flexDir={{ base: 'column', lg: 'row' }} bg={"easycar1.500"}>
                <Box order={{ base: 2, lg: 1 }} className={'sideLeft'} w={"full"} maxW={{ base: "full", lg: "40%" }} p={"6px"} pl={"5%"} m={"auto"} >
                    <Heading className={"colorWhite"}>Adquiere el vehículo que necesitas para seguir creciendo.</Heading>
                    <br/>
                    <Text as={"b"} className={"colorWhite"}>Un producto exclusivo para MYPE</Text>
                    <br /><br />
                    <Link to={"/cars"}>
                        <Button colorScheme={"easycar2"} >Precalifica ahora</Button>
                    </Link>
                </Box>
                <Flex order={{ base: 1, lg: 2 }} className={'sideRight'} bg={"white"} w={"full"} maxW={{ base: "full", lg: "60%" }} justifyContent={"end"} alignItems={"center"} overflow={"hidden"} maxHeight={"700px"}>
                    <Image src={banner2} alt={'Carousel Image'} h={"1000px"} w={"auto"} />
                </Flex>
            </Flex>



            <Flex w={"full"} flexDir={{ base: 'column', lg: 'row' }}>
                <Box order={{ base: 2, lg: 1 }} className={'sideLeft'} bg={"white"} w={"full"} maxW={{ base: "full", lg: "40%" }} p={"6px"} pl={"5%"} m={"auto"} >
                    <Heading>Llévatelo sin presentar comprobantes de ingresos</Heading>
                    <br/>
                    <Text as={"b"} fontSize={{ base: "initial", lg: "xl" }}>Se requiere monto de prima - DUI - Recibos de servicio</Text>
                    <br /><br />
                    <Link to={"/cars"}>
                        <Button colorScheme={"easycar1"} >Precalifica ahora</Button>
                    </Link>
                    <br /><br />
                    <Text fontSize={{ base: "initial", lg: "xl" }}>
                        *Condiciones y restricciones aplican
                    </Text>
                </Box>
                <Box order={{ base: 1, lg: 2 }} className={'sideRight'} w={"full"} maxW={{ base: "full", lg: "60%" }} >
                    <Image src={banner3} alt={'Carousel Image'} objectFit={'cover'} h={"700px"} w={"full"} />
                </Box>
            </Flex>


            <Flex w={"full"} flexDir={{ base: 'column', lg: 'row' }} bg={"easycar1.500"}>
                <Box order={{ base: 1, lg: 1 }} className={'sideLeft2'} bg={"white"} w={"full"} maxW={{ base: "full", lg: "60%" }}  >
                    <Image src={banner4} alt={'Carousel Image'} objectFit={'cover'} h={"700px"} w={"full"} />
                </Box>
                <Box order={{ base: 2, lg: 2 }} className={'sideRight2'} w={"full"} maxW={{ base: "full", lg: "40%" }} p={"6px"} pl={"5%"} m={"auto"} >
                    <Heading className='colorWhite'>Garantizamos tu experiencia en la compra, arrendamiento financiero y venta de vehículos</Heading>
                    <Text as={"b"} className='colorWhite'>Descubre nuestros vehículos de la más alta calidad</Text>
                    <br /><br />
                    <Link to={"/cars"}>
                        <Button colorScheme={"easycar2"} >Precalifica ahora</Button>
                    </Link>
                </Box>
            </Flex>

            <Flex w={"full"} flexDir={{ base: 'column', lg: 'row' }} bg={"easycar1.500"}>
                <Box order={{ base: 2, lg: 1 }} className={'sideLeft'} w={"full"} maxW={{ base: "full", lg: "40%" }} p={"6px"} pl={"5%"} m={"auto"} >
                    <Heading className='colorWhite'>Un año lleno de crecimiento para tu negocio con EASYCAR MYPE</Heading>
                    <Text as={"b"} className='colorWhite'>Adquiérelo en pagos mensuales con nuestro arrendamiento financiero</Text>
                    <br /><br />
                    <Link to={"/cars"}>
                        <Button colorScheme={"easycar2"} >Precalifica ahora</Button>
                    </Link>

                </Box>
                <Flex order={{ base: 1, lg: 2 }} className={'sideRight'} bg={"white"} w={"full"} maxW={{ base: "full", lg: "60%" }}  justifyContent={"end"} alignItems={"center"} overflow={"hidden"} maxHeight={"700px"}>
                    <Image src={banner5} alt={'Carousel Image'} h={"1000px"} w={"auto"} />

                </Flex>
            </Flex>

            <Flex w={"full"} flexDir={{ base: 'column', lg: 'row' }} bg={"easycar2.500"}>
                <Box order={{ base: 2, lg: 1 }} className={'sideLeft'} w={"full"} maxW={{ base: "full", lg: "40%" }} p={"6px"} pl={"5%"} m={"auto"} >
                    <Heading className='colorWhite'>¡Hemos evolucionado!</Heading>
                    <Text as={"b"} className='colorWhite' fontSize={{ base: "initial", lg: "2xl" }}>
                        Conoce nuestras agencias integrales
                    </Text>
                    <br /><br />
                    <Text as={"b"} className='colorWhite'>Exhibición de Vehículos - Taller automotriz - Arrendamiento Financiero</Text>
                    <br /><br />
                    <Link to={"/agencias"}>
                        <Button colorScheme={"easycar1"} >Ubicaciones</Button>
                    </Link>
                </Box>
                <Box order={{ base: 1, lg: 2 }} className={'sideRight'} w={"full"} maxW={{ base: "full", lg: "60%" }} >
                    <Image src={banner6} alt={'Carousel Image'} objectFit={'cover'} h={"700px"} w={"full"} />
                </Box>
            </Flex>

        </CarouselMulti>
    )
}