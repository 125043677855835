import React from 'react'
import Layout from '../layout'
import image1 from '../multimedia/imagenes/puntopago.jpg'
import image2 from '../multimedia/imagenes/puntoexpress.png'
import image3 from '../multimedia/logo-expandido.png'
import image4 from '../multimedia/imagenes/transferencia.png'
import { Flex, Box, Card, Heading, Text, Image } from '@chakra-ui/react'
import { Link } from 'react-router-dom/cjs/react-router-dom'

class PuntoPago extends React.Component {

    render() {

        return (
            <Layout>



                <Flex flexDir={{ base: 'column', lg: 'row' }} bg={'gray.50'}>
                    <Box order={{ base: 1, lg: 1 }} className={'sideLeft3'} w={"full"} maxW={{ base: "full", lg: "50%" }} >
                        <Image src={image1} alt={'Punto de pago'} objectFit={'cover'} h={"700px"} w={"full"} />
                    </Box>
                    <Box order={{ base: 1, lg: 1 }} className={'sideRight3'} w={"full"} maxW={{ base: "full", lg: "50%" }} p={"6px"} pl={"5%"} m={"auto"}>
                        <Heading>¡Hacemos más fácil tu forma de pago!</Heading>
                        <br />
                        <Text> Puedes realizar tus pagos en los siguientes puntos</Text>
                    </Box>
                </Flex>


                <Box w={"full"} bg={'gray.100'}>
                    <Flex textAlign={"center"} maxW={"1400px"} flexDir={'column'} p={4} m={"auto"} mt={"3.5rem"}>
                        <Heading>Canales de pago pensados para ti</Heading>
                        <Flex alignItems={"center"} justifyContent={"space-evenly"} p={"6"} minH={"500px"}>
                            <a target="_blank" rel="noreferrer" href={"https://www.puntoxpress.com/elsalvador/adonde-pago.html"}>
                                <Card w={"350px"} height={"250px"} display={"flex"} justifyContent={"center"} variant={"elevated"} shadow={"lg"} p={3} className='miniCarIcon'>
                                    <Image src={image2} maxH={"200px"} />
                                </Card>
                            </a>
                            <Link to={"agencias"}>
                                <Card w={"350px"} height={"250px"} display={"flex"} justifyContent={"center"} variant={"elevated"} shadow={"lg"} p={4} className='miniCarIcon'>
                                    <Image src={image3} width={"full"} h={"auto"} />
                                </Card>
                            </Link>
                            <a target="_blank" rel="noreferrer" href={"https://wa.me/50377380533?text=Hola,%20me%20gustar%C3%ADa%20pagar%20mi%20veh%C3%ADculo%20mediante%20transferencia."}>
                                <Card w={"350px"} height={"250px"} display={"flex"} justifyContent={"center"} alignItems={"center"} variant={"elevated"} shadow={"lg"} p={3} className='miniCarIcon'>
                                    <Image src={image4} h={"200px"} maxWidth={"250px"} />
                                </Card>
                            </a>
                        </Flex>
                    </Flex>
                </Box>
            </Layout>
        )
    }

}

export default PuntoPago